import { ToastContainer } from 'react-toastify';
import { Icon, IconButton } from '@intellecteu/common-ui';

import s from './styles.module.scss';

const CloseButton = ({ closeToast }: { closeToast?: () => any }) => (
  <IconButton onClick={closeToast} iconName={Icon.icons.close} showHover />
);

export const Toast = () => <ToastContainer className={s.notification} closeButton={<CloseButton />} />;
