import { PropsWithChildren, FC } from 'react';
import { ConfigProvider } from 'antd';

import colors from 'ui/styles/_colors.module.scss';

const AntdThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorPrimary: colors.darkBlue,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdThemeProvider;
