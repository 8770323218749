import React from 'react';
import { useFormikContext, useField } from 'formik';
import FormControl from '../control';

export interface FormFieldProps {
  component: any;
  name: string;
  id: string;
  value?: any;
  type?: string;
  ref?: (instance: any) => void;
  valueAdapter?: (...args: any) => any;
  onChangeAdapter?: (...args: any) => any;
  className?: string;
  placeholder?: string;
  title?: string;
  onBlur?: (...args: any) => any;
  datacy?: string;
  showError?: boolean;

  [key: string]: any;
}

const Field = React.forwardRef<any, FormFieldProps>((props: FormFieldProps, ref) => {
  if (!props.name) {
    console.warn(`Your field doesn't have any name property.`);
  }

  const formik = useFormikContext();
  const [field, meta] = useField(props);

  const { component, valueAdapter, onChangeAdapter, showError = true, ...leftProps } = props;

  const onChange = (...args) => {
    // eslint-disable-next-line react/prop-types
    formik.setFieldValue(props.name as never, (onChangeAdapter || props.onChange)(...args));
  };

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }
    formik.setFieldTouched(props.name as never, true);
  };

  const { error, touched, initialError } = meta;

  const fieldProps = {
    ...field,
    ...leftProps,
    ...(((showError && touched) || initialError) && { error }),
    id: props.id ?? props.name,
    touched: touched.toString(),
    value: valueAdapter ? valueAdapter(field.value) : field.value,
    onChange: onChangeAdapter ? onChange : field.onChange,
    onBlur,
    ref,
  };

  return <FormControl component={component} {...fieldProps} />;
});

Field.displayName = 'Field';

export { Field };
