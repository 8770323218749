import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

const Layout = loadable(() => import('./layout'));

const App = () => (
  <Routes>
    <Route path="/*" element={<Layout />} />
  </Routes>
);

export default App;
