import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BreadcrumbsProvider, Loader } from '@intellecteu/common-ui';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Toast } from './components/core';
import App from './pages';
import keycloak from 'data/keycloak';
import { useStore } from 'data';
import AntdThemeProvider from './components/core/antd-theme-provider';

import 'react-toastify/dist/ReactToastify.min.css';
import 'app/styles/global.css';

const container = document.getElementById('root') as HTMLElement;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const CatIM = () => {
  const { setAuthorization } = useStore();

  const eventLogger = (event: unknown) => {
    if (event && event === 'onAuthSuccess') {
      setAuthorization(keycloak);
    }
  };

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required' }}
      LoadingComponent={<Loader.FullPage />}
      authClient={keycloak}
      onEvent={eventLogger}
    >
      <AntdThemeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <BreadcrumbsProvider>
              <>
                <App />
                <Toast />
              </>
            </BreadcrumbsProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </AntdThemeProvider>
    </ReactKeycloakProvider>
  );
};

const root = createRoot(container);

root.render(<CatIM />);
