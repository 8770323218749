import data from './data.json';

let { url } = data;
let { realm } = data;
let { clientId } = data;

if (import.meta.env.VITE_MODE !== 'development') {
  url = window._env_.VITE_KEYCLOAK_URL;
  realm = window._env_.VITE_KEYCLOAK_REALM;
  clientId = window._env_.VITE_KEYCLOAK_CLIENT_ID;
}

export interface INameAuthProps {
  url: string;
  realm: string;
  clientId: string;
}

export const auth: INameAuthProps = {
  url: `${url}/auth`,
  realm,
  clientId,
};
