import { useMemo } from 'react';

const BASE_URL = window._env_?.CONFIG_BASEURL || import.meta.env.VITE_BASE_URL;
const UI_BUILD = window._env_?.VITE_UI_BUILD || import.meta.env.VITE_UI_BUILD || '1.0';
const POLLING_INTERVAL = window._env_?.VITE_POLLING_INTERVAL || import.meta.env.VITE_POLLING_INTERVAL || 5000;

export const useAppConfig = () =>
  useMemo(
    () => ({
      pollingInterval: Number(POLLING_INTERVAL),
      baseUrl: BASE_URL,
      version: UI_BUILD,
    }),
    [],
  );
