import { FC } from 'react';
import cx from 'classnames';

import s from './styles.module.scss';

interface Props {
  className?: string;
  component: any;
  disabled?: boolean;
  id: string;
  error?: string;
  [key: string]: any;
}

const FormControl: FC<Props> = ({ className, component: Input, error, disabled, ...props }) => (
  <div className={cx(s.formControl, className)}>
    <Input disabled={disabled} {...props} error={error} />

    <div className={cx(s.error, !error && s.errorHidden)}>{error}</div>
  </div>
);

export default FormControl;
